import * as React from 'react'
import cn from 'classnames'
import { PropsWithRequiredChildren } from '../../../types/helpers'
import styles from './SecondaryNav.module.scss'

export type HeaderProps = PropsWithRequiredChildren<
  React.ComponentPropsWithoutRef<'div'>
>

export const Header: React.VFC<HeaderProps> = (props) => {
  const { className, children, ...restProps } = props

  return (
    <div className={cn(styles.header, className)} {...restProps} id="header">
      {children}
    </div>
  )
}
