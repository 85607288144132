import { Button } from 'src/design-system'
import { Megaphone } from '@phosphor-icons/react'
import * as React from 'react'
import { WithAnalytics } from './with-analytics'

type GiveFeedbackButtonProps = {
  userSlug: string
  userId: string
  /**
   * Where the give feedback button is being clicked from, used for tracking
   */
  source: string
}

export const GiveFeedbackButton: React.VFC<GiveFeedbackButtonProps> = (
  props
) => {
  const { userSlug, userId, source } = props

  return (
    <WithAnalytics
      event={'$track_give_feedback_link_clicked'}
      params={{ source }}
    >
      <Button
        variant="outline"
        as="a"
        data-turbo-action="advance"
        className="flex flex-row items-center gap-x-2"
        href={`/users/${userSlug}?give_feedback&user_id=${userId}`}
      >
        <Megaphone weight="bold" aria-hidden className="h-3 w-3" />
        Give feedback
      </Button>
    </WithAnalytics>
  )
}
