import * as React from 'react'
import cn from 'classnames'
import { PropsWithRequiredChildren } from '../../../types/helpers'
import styles from './Title.module.scss'

export type TitleProps = {
  preTitle?: string
} & PropsWithRequiredChildren<React.ComponentPropsWithoutRef<'h1'>>

export const Title: React.VFC<TitleProps> = (props) => {
  const { className, children, preTitle, ...restProps } = props

  return (
    <div className={cn(styles.titleWrapper, className)} {...restProps}>
      {preTitle && <span className={styles.preTitle}>{preTitle}</span>}
      <h1 className={styles.title}>{children}</h1>
    </div>
  )
}
