import { Loader, TabbedContent } from 'src/design-system'
import * as React from 'react'
import { store } from 'store/index'
import * as SecondaryNav from 'components/secondary-nav'
import { useStore } from 'store/context'
import { WorkFeedLayout } from 'components/home-page/work-feed/wrapper'
import { observer } from 'mobx-react-lite'
import { LayoutButton } from 'components/home-page/work-feed/work-feed-nav/layout-button'
import { FeedbackPageTab } from './feedback-page-tab'
import { ManagerUserSelect } from 'components/manager-user-select'
import { convertValueToUserIds } from 'components/manager-user-select/utils'

export type tabIdType = 'received' | 'given' | 'requested' | 'requests'

export const FeedbackPage = observer(() => {
  const [loadingData, setLoadingData] = React.useState(true)

  const { activities, nonNullCurrentUser, userSkills } = useStore()

  React.useEffect(() => {
    const fetchData = async () => {
      const fetchActivities = async () => {
        await store.activities.fetchAllWithAllIncludes(1000, {
          user_id: 'all',
          record_type: ['FeedbackItem', 'FeedbackRequest'],
        })
      }
      await Promise.all([fetchActivities()])

      setLoadingData(false)
    }

    fetchData()
  }, [nonNullCurrentUser.id])

  const [tabId, setTabId] = React.useState('received' as tabIdType)
  const [managerSelectValue, setManagerSelectValue] = React.useState(
    nonNullCurrentUser.id
  )
  const userId = convertValueToUserIds(nonNullCurrentUser, managerSelectValue)

  const initialLayout = 'list'
  const [layout, setLayout] = React.useState<WorkFeedLayout>(initialLayout)
  const fucusSkillIds =
    userId.length === 1 && userId[0] == nonNullCurrentUser.id
      ? userSkills.focusedSkillIdsForUser(nonNullCurrentUser.id)
      : []

  return (
    <>
      {loadingData ? (
        <>
          <SecondaryNav.Root>
            <SecondaryNav.Header>Feedback</SecondaryNav.Header>
          </SecondaryNav.Root>
          <main className="p-10 overflow-x-auto pb-20 flex-1">
            <Loader className="my-6" />
          </main>
        </>
      ) : (
        <TabbedContent.Root
          initialTabId={tabId}
          id="feedback-page"
          data-testid="feedback-page"
          className="flex-1 max-w-full"
        >
          <SecondaryNav.Root>
            <SecondaryNav.Header className="flex flex-row items-center justify-between w-full">
              <div className="flex flex-row items-center gap-4">
                Feedback
                {nonNullCurrentUser.isManager && (
                  <ManagerUserSelect
                    defaultValue={nonNullCurrentUser.id}
                    onChange={(value) =>
                      setManagerSelectValue(
                        value ? value : nonNullCurrentUser.id
                      )
                    }
                    user={nonNullCurrentUser}
                  />
                )}
                <TabbedContent.Tabs
                  variant="pill"
                  className="gap-1 overflow-y-auto"
                  data-element-id="create-form-tabs"
                >
                  <TabbedContent.Tab
                    id="received"
                    key="received"
                    onClick={() => setTabId('received')}
                  >
                    Received
                  </TabbedContent.Tab>
                  <TabbedContent.Tab
                    id="given"
                    key="given"
                    onClick={() => setTabId('given')}
                  >
                    Given
                  </TabbedContent.Tab>
                  <TabbedContent.Tab
                    id="requested"
                    key="requested"
                    onClick={() => setTabId('requested')}
                  >
                    Requests sent
                  </TabbedContent.Tab>
                  <TabbedContent.Tab
                    id="requests"
                    key="requests"
                    onClick={() => setTabId('requests')}
                  >
                    Requests received
                  </TabbedContent.Tab>
                </TabbedContent.Tabs>
              </div>
              <SecondaryNav.Actions>
                <LayoutButton layout={layout} onLayoutChange={setLayout} />
              </SecondaryNav.Actions>
            </SecondaryNav.Header>
          </SecondaryNav.Root>
          <TabbedContent.Content tabId="received" key="received">
            <FeedbackPageTab
              activities={activities.filteredActivities({
                recordType: 'FeedbackItem',
                userId: userId,
              })}
              focusSkillIds={fucusSkillIds}
              layout={layout}
            />
          </TabbedContent.Content>
          <TabbedContent.Content tabId="requested" key="requested">
            <FeedbackPageTab
              activities={activities.filteredActivities({
                recordType: 'FeedbackRequest',
                userId: userId,
              })}
              focusSkillIds={fucusSkillIds}
              layout={layout}
            />
          </TabbedContent.Content>
          <TabbedContent.Content tabId="given" key="given">
            <FeedbackPageTab
              activities={activities.filteredActivities({
                recordType: 'FeedbackItem',
                userId: userId,
                feedbackType: 'itemGiven',
              })}
              focusSkillIds={fucusSkillIds}
              layout={layout}
            />
          </TabbedContent.Content>
          <TabbedContent.Content tabId="requests" key="requests">
            <FeedbackPageTab
              activities={activities.filteredActivities({
                recordType: 'FeedbackRequest',
                userId: userId,
                feedbackType: 'requestReceived',
              })}
              focusSkillIds={fucusSkillIds}
              layout={layout}
            />
          </TabbedContent.Content>
        </TabbedContent.Root>
      )}
    </>
  )
})
