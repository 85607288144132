import { SecondaryNav } from 'src/design-system'
import * as React from 'react'
import { FeedbackSection } from 'components/feedback-section'
import { useStore } from 'store/context'

export const FeedbackReceivedPage = () => {
  const { currentUser } = useStore()

  if (!currentUser) return null

  return (
    <>
      <SecondaryNav.Root>
        <SecondaryNav.Header>
          <SecondaryNav.Title>Feedback</SecondaryNav.Title>
        </SecondaryNav.Header>
      </SecondaryNav.Root>
      <div className="flex flex-col lg:flex-row bg-white min-h-full">
        <main
          className="container-md mt-10 md:px-14 max-w-[740px]"
          id="feedback-section"
        >
          <FeedbackSection user={currentUser} users={[currentUser]} />
        </main>
      </div>
    </>
  )
}
