import { forRails } from 'components/for-rails'
import { registerComponents } from '../src/utils/react/registry'
import { GiveFeedbackButton } from 'components/give-feedback-button'
import { FeedbackReceivedPage } from 'components/feedback-received-page'
import { FeedbackPage } from 'pages/feedback-page'

registerComponents({
  GiveFeedbackButton: forRails(GiveFeedbackButton),
  FeedbackReceivedPage: forRails(FeedbackReceivedPage),
  FeedbackPage: forRails(FeedbackPage),
})
