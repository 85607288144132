import * as React from 'react'
import { Activity } from 'store/modules/activities'
import {
  WorkFeedLayout,
  WorkFeedWrapper,
} from 'components/home-page/work-feed/wrapper'
import { ActivityGrid } from 'components/activity-feed/grid/activity-grid'
import { ActivityFeed } from 'components/activity-feed'

type FeedbackPageTabProps = {
  activities: Activity[]
  layout: WorkFeedLayout
  focusSkillIds: string[]
}
export const FeedbackPageTab = (props: FeedbackPageTabProps) => {
  const { activities, layout, focusSkillIds } = props

  const addItemActiveTab = 'feedback'
  const addItemTitle = 'New feedback...'

  return (
    <>
      {layout === 'list' && (
        <WorkFeedWrapper layout={layout}>
          <ActivityFeed
            activities={activities}
            focusSkillIds={focusSkillIds}
            hasNextPage={false}
            showFocusSkillName
            addItemActiveTab={addItemActiveTab}
            addItemTitle={addItemTitle}
          />
        </WorkFeedWrapper>
      )}
      {layout === 'grid' && (
        <WorkFeedWrapper layout={layout}>
          <ActivityGrid
            activities={activities}
            storyMode="none"
            addItemActiveTab={addItemActiveTab}
            addItemTitle={addItemTitle}
            onSubmitStory={async (addedUpdates, addingToStoryId) => {}}
          />
        </WorkFeedWrapper>
      )}
    </>
  )
}
