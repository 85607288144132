import { AvatarButtonGroup, Banner, Button, Loader } from 'src/design-system'
import emptyFeedImage from 'app/packs/images/empty-feed.svg'
import { ActivityItemsList } from 'components/activity-feed/activity-items-list'
import { CreateFormModalButton } from 'components/create-form-modal'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useStore } from 'store/context'
import { User } from 'store/modules/users'
import { avatarGroupProps } from '../../utils/user-helpers'

type FeedbackSectionProps = {
  user: User
  users: User[]
  hideHeader?: boolean
}

export const FeedbackSection = observer((props: FeedbackSectionProps) => {
  const { activities, users } = useStore()
  const { user, users: selectedUsers, hideHeader } = props
  const perPage = 30
  const [hasNextPage, setHasNextPage] = React.useState(true)
  const [pageNumber, setPageNumber] = React.useState(1)
  const userIds = React.useMemo(
    () => selectedUsers.map((user) => user.id),
    [selectedUsers]
  )

  const fetchFeedback = React.useCallback(
    async (number?: number) => {
      const response = await activities.fetchAllWithAllIncludes(
        perPage,
        { user_id: userIds, record_type: 'FeedbackItem' },
        number
      )
      const hasNextPage =
        response.meta?.pages && response.meta.pages > pageNumber
      setHasNextPage(!!hasNextPage)
    },
    [activities, perPage, pageNumber, userIds]
  )

  const items = activities.filteredActivities({
    recordType: ['FeedbackItem'],
    userId: userIds,
  })

  const loadMore = async () => {
    const nextPage = pageNumber + 1
    setPageNumber(nextPage)
    fetchFeedback(nextPage)
  }

  React.useEffect(() => {
    fetchFeedback()
  }, [fetchFeedback])

  const showEmptyState = items.length === 0

  if (activities.initialLoading()) {
    return (
      <div className="flex justify-center w-full">
        <Loader />
      </div>
    )
  }

  if (activities.error) {
    return <Banner variant="danger">{activities.error}</Banner>
  }

  return (
    <>
      {showEmptyState && (
        <div className="text-center mt-6 md:mt-10 lg:mt-24 relative">
          <img className="w-full" src={emptyFeedImage} alt="" />
          <h2 className="text-gray-900 text-2xl font-bold mb-2">
            No feedback received yet
          </h2>
          <p className="mb-6 text-gray-500 text-base">
            Feedback from your teammates will appear here.
          </p>
          <CreateFormModalButton
            className="mx-auto mb-4"
            label="Request Feedback"
            source="feedback-section-empty-state"
            tabs={['feedback']}
            user={user}
          />
          <div className="max-w-md mt-8 mx-auto flex flex-col gap-4 justify-center">
            <p>
              Want to give feedback? you&apos;ll find a button at the top of
              your colleague&apos;s profile pages.
            </p>
            <AvatarButtonGroup
              className="justify-center flex-wrap"
              avatars={avatarGroupProps(users.all.slice(0, 10), { href: true })}
              size="sm"
            />
          </div>
        </div>
      )}
      {!showEmptyState && (
        <section className="mb-8">
          {!hideHeader && (
            <div className="flex flex-row items-center justify-between">
              <h2 className="text-lg font-bold text-gray-900 mb-5">History</h2>
              <div>
                <CreateFormModalButton
                  className="mx-auto"
                  label="Request Feedback"
                  source="feedback_request-page-header"
                  tabs={['feedback']}
                  user={user}
                />
              </div>
            </div>
          )}
          <ActivityItemsList activities={items} focusSkillIds={[]} />
          {hasNextPage ? (
            <div className="flex flex-row items-center justify-center pb-5">
              <Button
                variant="outline"
                colourVariant="theme"
                size="default"
                onClick={loadMore}
              >
                {activities.loading ? 'Loading...' : 'Load more'}
              </Button>
            </div>
          ) : (
            <div className="flex flex-row items-center gap-x-2 ml-2.5">
              <span className="bg-gray-600 w-3 h-3 rounded-full flex-shrink-0" />
              <p className="mb-0 text-gray-600">Beginning of history</p>
            </div>
          )}
        </section>
      )}
    </>
  )
})
