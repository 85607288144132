import * as React from 'react'
import cn from 'classnames'
import styles from './SecondaryNav.module.scss'
import { PropsWithRequiredChildren } from '../../../types/helpers'

export type RootProps = PropsWithRequiredChildren<
  React.ComponentPropsWithoutRef<'div'>
>

export const Root: React.VFC<RootProps> = (props) => {
  const { className, children, ...restProps } = props

  return (
    <div className={cn(styles.root, className)} {...restProps}>
      {children}
    </div>
  )
}
